import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Employee } from '../../../modules/cs/common/components/employee';

import '../../generic-page.scss';

const CSShadowPalaceTipsPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/counter-side/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Shadow Palace - Tips</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/counterside/categories/shadow_all.png"
            alt="Shadow Palace"
          />
        </div>
        <div className="page-details">
          <h1>Shadow Palace - Tips for all floors</h1>
          <h2>Prepare yourself for the Shadow Palace.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297253"></div>
      <div className="page-content">
        <SectionHeader title="Generic tips" />
        <ul>
          <li>
            Compared to other modes, once you attack a room, you can't restart
            the fight by pausing and leaving. Doing so, will cost you one of the
            three Hearts that you receive after using the Ticket to enter a
            floor.
          </li>
          <li>
            Before you enter a stage, read the restrictions carefully and also
            check the enemies you will fight there. Some rooms can easily wipe
            your whole team if you make a mistake.
          </li>
          <li>
            All enemies you will fight in Shadow Palace are considered as C.O.
          </li>
          <li>
            If a floor has a boss, in reality it isn't marked as a boss type, so
            skills that normally don't work on them, will work here. The Ship is
            categorized as a boss though.
          </li>
        </ul>
        <SectionHeader title="Non-Awakened Employee Recommendations" />
        <p>
          SSR rarity employees will form the backbone of your team. Here's a
          list of most universal employees that work really well in Shadow
          Palace thanks to their kits.
        </p>
        <div className="employee-container">
          <Employee slug="edel-meitner" mode="icon" enablePopover />
        </div>
        <h4>Edel Meitner</h4>
        <p>
          Her summonlings can help you redirect/absorb massive amounts of damage
          from enemy skills.
        </p>
        <div className="employee-container">
          <Employee slug="yang-harim" mode="icon" enablePopover />
        </div>
        <h4>Yang Harim</h4>
        <p>
          She provides barriers, skill haste and ATK buffs, heals, and enemy ATK
          and ASPD debuffs. Just a great utility unit overall.
        </p>
        <div className="employee-container">
          <Employee slug="evelyn-keller" mode="icon" enablePopover />
        </div>
        <h4>Evelyn Keller</h4>
        <p>
          One of the best healers in the game, who also reduces damage taken by
          allies in her special’s AOE and buffs their ATK on her ult.
        </p>
        <div className="employee-container">
          <Employee slug="nanahara-chifuyu" mode="icon" enablePopover />
        </div>
        <h4>Nanahara Chifuyu</h4>
        <p>
          One of the best 3 cost strikers for PVE in the game. Tanky, with good
          utility and damage. Great pick for multiple rooms.
        </p>
        <div className="employee-container">
          <Employee slug="gaeun" mode="icon" enablePopover />
        </div>
        <h4>Gaeun</h4>
        <p>
          A strong AOE dps who has CC and debuffs. Just like Chifuyu, she is a
          great pick for many rooms.
        </p>
        <div className="employee-container">
          <Employee slug="kyle-wong" mode="icon" enablePopover />
        </div>
        <h4>Kyle Wong</h4>
        <p>
          His walls can redirect/absorb damage while also stunning enemies,
          which can interrupt their skills if timed correctly. His ult also has
          good CC.
        </p>
        <div className="employee-container">
          <Employee slug="serapel" mode="icon" enablePopover />
          <Employee slug="lee-yumi" mode="icon" enablePopover />
        </div>
        <h4>Serapel and Lee Yumi</h4>
        <p>Good defender choices in general.</p>
        <div className="employee-container">
          <Employee slug="rosaria-le-friede" mode="icon" enablePopover />
          <Employee slug="lyudmila" mode="icon" enablePopover />
          <Employee slug="rita-arsenico" mode="icon" enablePopover />
        </div>
        <h4>Rosaria le Friede, Lyudmila and Rita</h4>
        <p>
          Air units can avoid skills and attacks from certain enemies, which can
          be invaluable. Lyudmila also provides very strong CC on both her skill
          and ult.
        </p>
        <div className="employee-container">
          <Employee slug="kestrel-xiao-lin" mode="icon" enablePopover />
        </div>
        <h4>Xiao Lin</h4>
        <p>
          Just an extremely strong dps, especially against strikers, with a
          massive burst of damage on her ult.{' '}
        </p>
        <SectionHeader title="2-Cost Employee Recommendations" />
        <p>
          If your teams are looking a bit too high on cost, consider these cheap
          but great units.
        </p>
        <div className="employee-container">
          <Employee slug="administration-rifleman" mode="icon" enablePopover />
          <Employee slug="administration-shieldman" mode="icon" enablePopover />
        </div>
        <h4>Administrator Rifleman and Shieldman</h4>
        <p>
          Both of these units shine in Shadow Palace - one brings tons of
          damage, while the other can tank and absorb valid hits.
        </p>
        <div className="employee-container">
          <Employee slug="eddie-fisher" mode="icon" enablePopover />
        </div>
        <h4>Eddie Fisher</h4>
        <p>Great buffer for both Soldiers and Counters.</p>
        <div className="employee-container">
          <Employee slug="assault-trooper" mode="icon" enablePopover />
        </div>
        <h4>Assault Trooper</h4>
        <p>
          Both great damage dealer and paratrooper that can attack the backline.
        </p>
        <div className="employee-container">
          <Employee slug="cindy-looper" mode="icon" enablePopover />
        </div>
        <h4>Cindy Looper</h4>
        <p>She can act as a tank in stages where defenders are banned</p>
        <div className="employee-container">
          <Employee slug="eins-and-zwei" mode="icon" enablePopover />
        </div>
        <h4>Eins & Zwei</h4>
        <p>Tanky unit with large amounts of CC on ult.</p>
        <div className="employee-container">
          <Employee slug="mone" mode="icon" enablePopover />
        </div>
        <h4>Mone</h4>
        <p>
          The best 2 cost defender available. She has good stats, while also
          being able to skill seal enemies. This can be invaluable for stages
          with enemies who have strong skills.
        </p>
        <SectionHeader title="Awakened Employee Recommendations" />
        <div className="employee-container">
          <Employee
            slug="awakened-yuna-springfield"
            mode="icon"
            enablePopover
          />
        </div>
        <h4>Awakened Yuna Springfield</h4>
        <p>
          A tank/damage dealer hybrid that can deal millions of damage with her
          passive alone. Often it’s enough to deploy her first and just support
          her with other characters to pass a stage.
        </p>
        <div className="employee-container">
          <Employee slug="ministra" mode="icon" enablePopover />
        </div>
        <h4>Ministra</h4>
        <p>
          Brings lots of debuffs and large amounts of AOE damage. If her hp
          drops to 60% or below, she also backsteps and summons an unhealable
          Spira, which can buy time for your team.
        </p>
        <div className="employee-container">
          <Employee slug="awakened-seo-yoon" mode="icon" enablePopover />
        </div>
        <h4>Awakened Seo Yoon</h4>
        <p>
          A Ranger/Sniper hybrid summoner and damage dealer that also can keep
          herself alive by jumping to the backline once her health drops. Her
          heal-block is also useful in one of the floors.
        </p>
        <div className="employee-container">
          <Employee slug="awakened-hilde" mode="icon" enablePopover />
        </div>
        <h4>Awakened Hilde</h4>
        <p>
          Deploy her first and let her tank everything while your damage dealers
          deal with the floor. Since there are no stars here, you just need to
          beat the stage before the 3 minutes timer reaches zero. Still, both
          Yuna and Seo Yoon are better choices, especially for cost-restricted
          rooms, so use them over Hilde if you have them.
        </p>
        <div className="employee-container">
          <Employee slug="awakened-jake" mode="icon" enablePopover />
        </div>
        <h4>Awakened Jake</h4>
        <p>
          A better tank than Awakened Hilde in every way. Hp% drain and ATK
          debuffs within his stasis field; CC, skill seal and ASPD debuffs on
          his ult; more constant self shielding than Awakened Hilde
        </p>
        <div className="employee-container">
          <Employee slug="replacer-queen" mode="icon" enablePopover />
        </div>
        <h4>Replacer Queen</h4>
        <p>
          Provides large amounts of AOE damage while also skill sealing enemies
          periodically.
        </p>
        <div className="employee-container">
          <Employee slug="awakened-lee-sooyeon" mode="icon" enablePopover />
        </div>
        <h4>Awakened Lee Sooyeon</h4>
        <p>
          Self shielding and CC on skill, massive AOE damage and knockback on
          ult, and buffs for ally non-awakened ranger counters
        </p>
        <h5>Final notes</h5>
        <p>
          This is not an exhaustive list. There are many other employees you can
          use!
        </p>
      </div>
    </DashboardLayout>
  );
};

export default CSShadowPalaceTipsPage;

export const Head: React.FC = () => (
  <Seo
    title="Shadow Palace tips and tricks | Counter Side | Prydwen Institute"
    description="Prepare yourself for the Shadow Palace."
  />
);
